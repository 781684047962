import { useMemo } from "react";

import useResizeWindow from "hooks/use-resize-window";

import { SIZE } from "constants/styles";

const useWindowSizes = ({ isClient = false } = {}) => {
  const [windowSize] = useResizeWindow({ isClient });

  return useMemo(
    () => ({
      isMobileWidth: SIZE.getIsMobileWidth(windowSize),
      isMobileLargeWidth: SIZE.getIsMobileLargeWidth(windowSize),
      isMobileMediumWidth: SIZE.getIsMobileMediumWidth(windowSize),
      isMobileSmallWidth: SIZE.getIsMobileSmallWidth(windowSize),
      isTabletSmallWidth: SIZE.getIsTabletSmallWidth(windowSize),
      isTabletWidth: SIZE.getIsTabletWidth(windowSize),
      isTabletLargeWidth: SIZE.getIsTabletLargeWidth(windowSize),
      isDesktopSmallWidth: SIZE.getIsDesktopSmallWidth(windowSize),
      isDesktopMediumWidth: SIZE.getIsDesktopMediumWidth(windowSize),
      isDesktopLargeWidth: SIZE.getIsDesktopLargeWidth(windowSize),
      isDesktopWidth: SIZE.getIsDesktopWidth(windowSize),
    }),
    [windowSize],
  );
};

export default useWindowSizes;
