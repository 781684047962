export enum ScreenWidth {
  desktopLarge = 1440,
  desktopMedium = 1360,
  desktopSmall = 1200,
  tabletLarge = 1024,
  tabletSmall = 768,
  mobileLarge = 600,
  mobileMedium = 432,
}

/*
  0                       432                      600                       768                      1024                    1200                     1360                     1440                      +∞
  |------------------------|------------------------|-------------------------|------------------------|------------------------|------------------------|------------------------|------------------------|
  |   isMobileSmallWidth   |   isMobileMediumWidth  |   isMobileLargeWidth    |   isTabletSmallWidth   |   isTabletLargeWidth   |   isDesktopSmallWidth  |  isDesktopMediumWidth  |  isDesktopLargeWidth   |
  |---------------------------------------------------------------------------|                                                 |                                                                          |
                            isMobileWidth                                     |-------------------------------------------------|                                                                          |
                                                                                                 isTabletWidth                  |--------------------------------------------------------------------------|
                                                                                                                                                               isDesktopWidth
*/

interface WindowSize {
  width: number;
  height: number;
}

export const SIZE = new (class {
  public getIsMobileWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.tabletSmall;

  public getIsMobileSmallWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.mobileMedium;

  public getIsMobileMediumWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.mobileLarge && width >= ScreenWidth.mobileMedium;

  public getIsMobileLargeWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.tabletSmall && width >= ScreenWidth.mobileLarge;

  public getIsTabletSmallWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.tabletLarge && width >= ScreenWidth.tabletSmall;

  public getIsTabletLargeWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.desktopSmall && width >= ScreenWidth.tabletLarge;

  public getIsTabletWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.desktopSmall && width >= ScreenWidth.tabletSmall;

  public getIsDesktopSmallWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.desktopMedium && width >= ScreenWidth.desktopSmall;

  public getIsDesktopMediumWidth = ({ width }: WindowSize) =>
    width < ScreenWidth.desktopLarge && width >= ScreenWidth.desktopMedium;

  public getIsDesktopLargeWidth = ({ width }: WindowSize) =>
    width >= ScreenWidth.desktopLarge;

  public getIsDesktopWidth = ({ width }: WindowSize) =>
    width >= ScreenWidth.desktopSmall;
})();
