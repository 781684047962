import { useEffect, useState } from "react";
import isFunction from "lodash/isFunction";

interface StoreListener<T> {
  (value: T, prev: T): void;
}

class States<T extends object> {
  private listeners = new Set<StoreListener<T>>();
  constructor(public state: T) {}

  public setState(value: Partial<T> | ((p: T) => T)) {
    const prevState = { ...this.state };

    this.state = isFunction(value)
      ? value(this.state)
      : { ...this.state, ...value };

    const stateSnapshot = {
      ...this.state,
    };
    for (const listener of this.listeners) {
      listener(stateSnapshot, prevState);
    }
  }

  public subscribe(listener: StoreListener<T>) {
    this.listeners.add(listener);

    return () => {
      this.listeners.delete(listener);
    };
  }

  public select<R>(selector: (state: T) => R) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState(() => selector(this.state));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
      () => this.subscribe(state => setValue(selector(state))),
      [selector],
    );

    return value;
  }
}

export default States;
