import { Dispatch, ReactNode, SetStateAction } from "react";

import { DialogData, DialogRender, OpenDialogOptions } from "types/dialog";

import { Context } from "constants/dialog";

import { sleep } from "./async";
import States from "./states";

abstract class RenderConnector {
  private setRenderDialog: Dispatch<SetStateAction<Map<string, DialogRender>>> =
    () => new Map();

  public connectRender(
    callback: Dispatch<SetStateAction<Map<string, DialogRender>>>,
  ) {
    this.setRenderDialog = callback;
  }

  protected addDialog(id: string, data: DialogRender) {
    this.setRenderDialog(previous => {
      previous.set(id, data);

      return new Map(previous);
    });
  }

  protected removeDialog(id: string) {
    this.setRenderDialog(previous => {
      previous.delete(id);
      return new Map(previous);
    });
  }
}

const dialogAPI = new (class extends RenderConnector {
  private idGen = 0;

  public data = new Map<string, DialogData>();

  private alertsMaxCount = 3;

  private get nextId(): string {
    return String(++this.idGen);
  }

  public open<R>(
    node: ReactNode,
    {
      id = this.nextId,
      type = "fullscreen",
      animationDelay = 0,
    }: Partial<OpenDialogOptions> = {},
  ): Promise<R | null> {
    return new Promise<R | null>((resolve, reject) => {
      const dialogState = new States({ isOpened: true });
      const wrappedNode = (
        <Context.Provider
          key={id}
          value={{
            resolve: (value: R) => this.close(id, () => resolve(value)),
            reject: (error: Error) => this.close(id, () => reject(error)),
            id,
            state: dialogState,
            animationDelay,
          }}
        >
          {node}
        </Context.Provider>
      );

      if (type === "alert") {
        const alerts = Array.from(this.data.values()).filter(
          dialog => dialog.type === "alert",
        );

        if (alerts.length === this.alertsMaxCount) {
          this.close(alerts[0].id);
        }
      }

      this.data.set(id, {
        resolve,
        reject,
        id,
        type,
        state: dialogState,
        animationDelay,
      });

      this.addDialog(id, { node: wrappedNode, type });
    });
  }

  public openDrawer<R>(node: ReactNode) {
    return this.open<R>(node, { animationDelay: 300 });
  }

  public async close(
    id: string,
    closeCallback: ((data: DialogData) => void) | null = null,
  ): Promise<DialogData | null> {
    const dialog = this.data.get(id);
    if (!dialog) {
      return null;
    }

    const { state, animationDelay } = dialog;

    state.setState({ isOpened: false });
    await sleep(animationDelay);

    this.data.delete(id);
    this.removeDialog(dialog.id);

    if (closeCallback) {
      closeCallback(dialog);
    } else {
      dialog.resolve(null);
    }

    return dialog;
  }
})();

export default dialogAPI;
